import track from "lib/tracking/track";

/**
 * @param {HTMLElement} el - CTA element
 * @returns payload for cta_clicked user activity event.
 */
function getCtaClickedPayload(el) {
  const {
    trackType: ctaType,
    trackLabel: label,
    trackLocation: pageLocation
  } = el.dataset;
  const ctaHref = el.getAttribute("href");
  const lang = document.documentElement.lang;
  return { ctaHref, ctaType, label, lang, pageLocation };
}

/**
 * Handle clicks on container using event delegation.
 * @param e {MouseEvent} - click event
 */
function handleClick(e) {
  const ctaEl = e.target.closest("[data-track-cta]");
  if (ctaEl) {
    track("cta_clicked", getCtaClickedPayload(ctaEl));
  }
}

/**
 * Add CTA click track listener to given container element.
 * @param {HTMLElement} containerEl - container element for listener
 */
export function addCtaClickListener(containerEl) {
  containerEl.addEventListener("click", handleClick);
}
